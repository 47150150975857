import {
  Root,
  Thumb,
  SwitchProps as RadixSwitchProps,
} from '@radix-ui/react-switch';
import { clsx } from 'clsx';
import { forwardRef } from 'react';
import { switchRoot, switchThumb } from './switch.css.ts';

export type SwitchProps = RadixSwitchProps & {
  inversed?: boolean;
};

export const Switch = forwardRef<HTMLButtonElement, SwitchProps>(
  function CheckboxComponent({ children, className, inversed, ...rest }, ref) {
    return (
      <Root
        ref={ref}
        data-inversed={inversed ? '' : undefined}
        className={clsx(className, switchRoot)}
        {...rest}
      >
        <Thumb className={switchThumb} />
      </Root>
    );
  },
);
