import { gql } from '@/__generated__';
import { useMutation } from '@apollo/client';
import { PushNotificationSubscriptionInput } from '@/__generated__/graphql.ts';

const RemovePushNotificationsSubscription = gql(`
  mutation RemovePushNotificationsSubscription($input: PushNotificationSubscriptionInput!) {
    removePushNotificationsSubscription(input: $input)
  }
`);
export const useRemovePushSubscription = () => {
  const [removeSubscriptionMutation, result] = useMutation(
    RemovePushNotificationsSubscription,
  );

  const removePushSubscription = (input: PushNotificationSubscriptionInput) => {
    return removeSubscriptionMutation({
      variables: { input },
    });
  };

  return [removePushSubscription, result] as const;
};
