import { Drawer as DrawerPrimitive } from 'vaul';
import { clsx } from 'clsx';
import {
  drawerContent,
  drawerContentHandle,
  drawerContentVariants,
  drawerDescription,
  drawerFooter,
  drawerHeader,
  drawerOverlay,
  drawerTitle,
} from './drawer.css.ts';
import {
  ComponentProps,
  ComponentPropsWithoutRef,
  ElementRef,
  FC,
  forwardRef,
  HTMLAttributes,
} from 'react';

const Drawer = ({ ...props }: ComponentProps<typeof DrawerPrimitive.Root>) => (
  <DrawerPrimitive.Root noBodyStyles={true} {...props} />
);

const DrawerTrigger: FC<
  ComponentPropsWithoutRef<typeof DrawerPrimitive.Trigger>
> = ({ children, ...props }) => (
  <DrawerPrimitive.Trigger {...props} style={{ all: 'unset' }}>
    {children}
  </DrawerPrimitive.Trigger>
);

export const DrawerClose: FC<
  ComponentPropsWithoutRef<typeof DrawerPrimitive.Close>
> = ({ children, ...props }) => (
  <DrawerPrimitive.Close {...props} style={{ all: 'unset' }}>
    {children}
  </DrawerPrimitive.Close>
);

const DrawerOverlay = forwardRef<
  ElementRef<typeof DrawerPrimitive.Overlay>,
  ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
>(function DrawerOverlayComponent({ className, ...props }, ref) {
  return (
    <DrawerPrimitive.Overlay
      ref={ref}
      className={clsx(drawerOverlay, className)}
      {...props}
    />
  );
});

interface DrawerContentProps
  extends ComponentPropsWithoutRef<typeof DrawerPrimitive.Content> {
  inversed?: boolean;
  withHandle?: boolean;
  direction?: 'top' | 'right' | 'bottom' | 'left';
}

const DrawerContent = forwardRef<
  ElementRef<typeof DrawerPrimitive.Content>,
  DrawerContentProps
>(function DrawerContentComponent(
  {
    className,
    children,
    inversed,
    withHandle = true,
    direction = 'bottom',
    ...props
  },
  ref,
) {
  return (
    <DrawerPrimitive.Portal>
      <DrawerOverlay />
      <DrawerPrimitive.Content
        ref={ref}
        className={clsx(
          drawerContent,
          drawerContentVariants[direction],
          className,
        )}
        data-inversed={inversed ? '' : undefined}
        {...props}
      >
        {withHandle ? (
          <DrawerPrimitive.Handle
            className={drawerContentHandle}
            data-inversed={inversed ? '' : undefined}
          />
        ) : null}
        {children}
      </DrawerPrimitive.Content>
    </DrawerPrimitive.Portal>
  );
});

const DrawerHeader = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div className={clsx(drawerHeader, className)} {...props} />
);

const DrawerFooter = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div className={clsx(drawerFooter, className)} {...props} />
);

const DrawerTitle = forwardRef<
  ElementRef<typeof DrawerPrimitive.Title>,
  ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>
>(function DrawerTitleComponent({ className, ...props }, ref) {
  return (
    <DrawerPrimitive.Title
      ref={ref}
      className={clsx(drawerTitle, className)}
      {...props}
    />
  );
});

const DrawerDescription = forwardRef<
  ElementRef<typeof DrawerPrimitive.Description>,
  ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>
>(function DrawerDescriptionComponent({ className, ...props }, ref) {
  return (
    <DrawerPrimitive.Description
      ref={ref}
      className={clsx(drawerDescription, className)}
      {...props}
    />
  );
});

export {
  Drawer,
  DrawerOverlay,
  DrawerTrigger,
  DrawerContent,
  DrawerHeader,
  DrawerFooter,
  DrawerTitle,
  DrawerDescription,
};
