// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgHalfMoon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M8.67 2.362A.75.75 0 0 1 9.023 3c0 4.14 1.026 7.095 2.953 9.023 1.928 1.927 4.884 2.953 9.023 2.953a.75.75 0 0 1 .672 1.084 10.24 10.24 0 0 1-9.179 5.69c-5.657 0-10.243-4.586-10.243-10.243 0-4.023 2.32-7.503 5.69-9.179a.75.75 0 0 1 .73.034ZM7.556 4.29A8.743 8.743 0 1 0 19.71 16.444c-3.753-.197-6.717-1.283-8.794-3.36S7.754 8.043 7.556 4.29"
      clipRule="evenodd"
    />
  </svg>
);
export const HalfMoonIcon = forwardRef(SvgHalfMoon);
