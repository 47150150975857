import {
  useThemeMode,
  useThemeSwitch,
} from '@synoptic/ui-kit/theme-context.js';
import { HalfMoonIcon } from '@synoptic/ui-kit/icons/react/half-moon.js';
import { Switch } from '@synoptic/ui-kit/switch/switch.js';
import clsx from 'clsx';
import { menuItemLink } from './menu/menu-action/menu-action.css';
import { profileMenuItem, profileMenuSwitch } from './profile-menu.css';

export const DarkModeToggle = () => {
  const theme = useThemeMode();
  const switchTheme = useThemeSwitch();

  return (
    <label className={clsx(menuItemLink, profileMenuItem)}>
      <HalfMoonIcon />
      Dark mode
      <Switch
        inversed
        className={profileMenuSwitch}
        checked={theme === 'dark'}
        onCheckedChange={(checked) => {
          switchTheme(checked ? 'dark' : 'light');
        }}
      />
    </label>
  );
};
