import { clsx } from 'clsx';
import { forwardRef, ReactNode } from 'react';
import { menuItemIcon, menuItemLink, menuItemText } from './menu-action.css.ts';
import { NavLink, NavLinkProps } from '@/routes-utils/navigation.tsx';

type MenuActionLinkProps = {
  icon: ReactNode;
  label: string;
  external?: boolean;
} & NavLinkProps;

export const MenuActionLink = forwardRef<
  HTMLAnchorElement,
  MenuActionLinkProps
>(function MenuActionLink(
  { icon, label, to, external, className, ...props },
  ref,
) {
  return (
    <NavLink
      ref={ref}
      to={to}
      target={external ? '_blank' : ''}
      className={clsx(menuItemLink, className)}
      prefetch="render"
      {...props}
    >
      <div className={menuItemIcon}>{icon}</div>
      <span className={menuItemText}>{label}</span>
    </NavLink>
  );
});
